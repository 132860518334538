<template>
  <Card class="card-custom">
    <template #title>
      <Toolbar>
        <template #start> </template>
        <template #end>
          <Button
            :label="$t('create_segment')"
            icon="pi pi-plus"
            class="p-button-sm p-button-success"
            @click="$router.push('/segments/create')"
          />
        </template>
      </Toolbar>
    </template>
    <template #content>
      <!-- <v-chip-group>
        <v-chip
          class="active-filter-chip mr-1"
          v-if="Object.keys(filteredActiveFilters).length"
          @click="clearAllFilters"
        >
          <template>
            {{ $t("clear_all") }}
          </template>
          <v-icon>mdi-close</v-icon>
        </v-chip>
        <v-chip
          class="active-filter-chip mr-1"
          v-for="(value, key) in filteredActiveFilters"
          :key="'filter' + key"
          @click="removeFilter(key)"
        >
          <template>
            {{ $t(key) }}: {{ getFilterValueText(key, value) }}
          </template>
          <v-icon @click.stop="removeFilter(key)">mdi-close</v-icon>
        </v-chip>
      </v-chip-group> -->
      <DataTable
        :value="segments"
        :rows="10"
        :loading="loadingFlag"
        :paginator="true"
        filterDisplay="row"
        responsiveLayout="responsive"
        :scrollable="true"
        stripedRows
        removableSort
        class="p-datatable-sm"
      >
      <template #empty>
        <b class="">
            {{ $t('no_data')  }}
        </b>
      </template>
        <Column
          sortable
          field="id"
          header="ID"
          :showFilterMenu="false"
          :styles="{ width: '5rem' }"
        >
          <template #body="{ data }">
            <div>
              {{ data.id }}
            </div>
          </template>
          <template #filter> </template>
        </Column>
        <Column
          sortable
          field="name"
          :header="$t('name')"
          :showFilterMenu="false"
          :styles="{ width: '5rem' }"
        >
          <template #body="{ data }">
            <div>
              {{ data.name }}
            </div>
          </template>
          <template #filter> </template>
        </Column>
        <Column
          sortable
          field="description"
          :header="$t('description')"
          :showFilterMenu="false"
          :styles="{ width: '5rem' }"
        >
          <template #body="{ data }">
            <div>
              {{ data.description }}
            </div>
          </template>
          <template #filter> </template>
        </Column>
        <Column :exportable="false" :header="$t('actions')">
          <template #body="{ data, index }">
            <div class="d-flex align-center">
              <Button
                icon="pi pi-pencil"
                :disabled="data.loadingItem"
                :loading="data.loadingItem"
                class="p-button-sm p-button-rounded p-button-warning mr-2"
                @click="toEditSegment(data)"
              />
              <Button @click="toDeleteSegment(index)" 
               class="p-button-sm p-button-rounded p-button-danger"
            :disabled="data.loadingItem" :loading="data.loadingItem" 
            aria:haspopup="true" :aria-controls="`overlay_panel_${index}`"
              icon="pi pi-trash" 
              />
              <OverlayPanel :ref="`op_${index}`" appendTo="body" :id="`overlay_panel_${index}`" class="p-confirm-popup">
                <div class="p-confirm-popup-content">
                  <i class="p-confirm-popup-icon pi pi-exclamation-triangle"></i>
                  <div class="p-confirm-popup-message d-flex flex-column" 
                    style="max-width:400px"
                    >
                        <h3>{{ $t('attention_title') }}</h3>
                        <span>{{ $t('segment_will_delete') }}</span>
                        <h4 style="margin-top: 5px;">{{ $t('attention_question') }}</h4>
                    </div>
                </div>
                <div class="p-confirm-popup-footer">
                    <span class="p-buttonset">
                        <Button @click="toCloseOverlay(index)" :disabled="data.loadingItem" :loading="data.loadingItem" 
                        :label="$t('no')"
                         class="p-button-sm p-button-warning" />
                        <Button @click="toConfirmDelete(data, index)" 
                        :disabled="data.loadingItem" :loading="data.loadingItem" 
                        :label="$t('confirm')"
                          class="p-button-sm p-button-danger" />
                      </span>
                </div>
              </OverlayPanel>
            </div>
          </template>
        </Column>
      </DataTable>
      <!-- <games-paggination
        :pagginationObject="filters"
        :requestData="segments"
        @apply-filters="applyFilters"
        :isStatic="false"
      /> -->
    </template>
  </Card>
</template>

<script>
// import GamesPaggination from "@/views/GamesPaggination";
import { mapGetters } from "vuex";
import { debounce } from "lodash";
export default {
  name: "ViewSegments",
  components: {
    // GamesPaggination,
  },
  computed: {
    ...mapGetters({
      segments: "segments/getSegments",
      currencies: "getFilteredCurrencies",
    }),
  },
  data() {
    return {
      loadingFlag: false,
      formLoading: false,
    //   activeFilters: {},
      filters: {
        page: 1,
        size: 25,
      },
    };
  },
  watch: {},
  async mounted() {
    this.loadingFlag = true;
    // if (this.segments.items.length === 0) {
    //     await this.applyFilters();
    // }
    await this.applyFilters();
    this.loadingFlag = false;
  },
  methods: {
    getBonusActiveClass(bonus) {
      switch (bonus) {
        case true:
          return "_green";
        case false:
          return "_red";
        default:
          return "_gray";
      }
    },
    // getFilterValueText(key, value) {
    //   // chip values
    //   switch (value) {
    //     case true:
    //       return this.$t("yes");
    //     case false:
    //       return this.$t("no");
    //   }
    //   if (key === "type") {
    //     return this.$t(value);
    //   } else {
    //     return value;
    //   }
    // },
    // async removeFilter(key) {
    //   this.loadingFlag = true;
    //   // Delete chip
    //   this.$delete(this.activeFilters, key);
    //   // empty string
    //   if (typeof this.filters[key] === "boolean") {
    //     this.$set(this.filters, key, false);
    //   } else {
    //     this.$set(this.filters, key, "");
    //   }
    //   this.applyFilters();
    // },
    // clearAllFilters() {
    //   this.loadingFlag = true;
    //   const page = this.filters.page;
    //   const size = this.filters.size;
    //   Object.keys(this.filters).forEach((key, index) => {
    //     this.$delete(this.filters, key);
    //   });
    //   Object.keys(this.activeFilters).forEach((key, index) => {
    //     this.$delete(this.activeFilters, key);
    //   });
    //   this.filters.page = page;
    //   this.filters.size = size;
    //   this.applyFilters();
    // },
    // holdActiveFilters() {
    //   for (const [key, value] of Object.entries(this.filters)) {
    //     if (value !== "") {
    //       this.$set(this.activeFilters, key, value);
    //     } else {
    //       this.$delete(this.activeFilters, key, value);
    //     }
    //   }
    // },
    //========================================================================================================================================================

    // debounceFilters: debounce(async function () {
    //   await this.applyFilters();
    // }, 550),
    async applyFilters() {
      this.loadingFlag = true;
      this.$store.commit("paggination/SET_FILTERS", this.filters);
      try {
        await this.$store.dispatch(
          "segments/awaitGetSegments",
          this.filters
        );
      } catch (error) {
        this.$toast.add({
          severity: "error",
          summary: this.$t("just_error"),
          life: 4000,
        });
      } finally {
        // this.holdActiveFilters();
        this.$store.commit("paggination/SET_FILTERS", this.filters);
        this.loadingFlag = false;
        this.$store.commit("paggination/SET_LOADING_FLAG", false);
      }
    },
    async toEditSegment(segment) {
      this.$router.push(`/segments/edit/${segment.id}`);
    },
    toDeleteSegment(index){
                this.$refs[`op_${index}`].toggle(event);
            },
    toCloseOverlay(index){
                this.$refs[`op_${index}`].hide(event);
    },
    async toConfirmDelete(data, index) {
      this.formLoading = true;
      try {
        await this.$store.dispatch(
          "segments/deleteSegment",
          data.id
        );
        this.applyFilters();
        this.$toast.add({
          severity: "success",
          summary: this.$t("segment_deleted"),
          life: 4000,
        });
      } catch (error) {
        this.$toast.add({
          severity: "error",
          summary: this.$t("just_error"),
          detail: error.message,
          life: 4000,
        });
      } finally {
        this.$refs[`op_${index}`].toggle(event);
        this.formLoading = false;
      }
    },
  },
};
</script>

<style scoped></style>
